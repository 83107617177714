
import React from 'react';
import { withTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';

import RootData from './RootData';
import LoadSelect from './LoadSelect';


class LoadDialogClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (!RootData.loadSelect) {
            const container = document.getElementById('loadSelect');
            RootData.loadSelect = createRoot(container);
        }
        RootData.loadSelect.render(
            <React.StrictMode>
                <LoadSelect server={server} />
            </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tiny modal" id="loadDialog">
                <div className="header"> {t('dialog.LoadProject')} </div>
                <div className="content">
                    {t('dialog.LoadProject')}: <div id="loadSelect"></div>
                </div>
                <div className="actions">
                    <div className="ui ok button"> {t('dialog.Ok')} </div>
                    <div className="ui cancel button"> {t('dialog.Cancel')} </div>
                </div>
            </div>
        );
    }
};

const LoadDialog = withTranslation()(LoadDialogClass);
export default LoadDialog;
