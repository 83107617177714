
import React from 'react';
import { withTranslation } from 'react-i18next';


class TargetListClass extends React.Component {
    constructor(props) {
        super(props);
        this.onTargetClick = this.onTargetClick.bind(this);
        this.onDocTargetClick = this.onDocTargetClick.bind(this);
        this.onInfoTargetClick = this.onInfoTargetClick.bind(this);
        this.state = {
            items: {doc: [], target: [], dynamic: [], info: [], clean: [], tests: []}
        };
    }

    onTargetClick (projectName, name) {
        const server = this.props.server;
        server.executeProjectTarget (projectName, name, (data) => {
            let str = "<div>";
            if (data.result)
                str += "<pre>" + data.result + "</pre>";
            if (data.resultData)
                str += data.resultData;
            if (data.error)
                str += "<pre class='errorMsg'>" + data.error + "</pre>";
            if ((data.status === false) && (!data.error))
                str += "<pre class='errorMsg'>Status: failed!</pre>";
            str += "</div>"
            document.getElementById("targetContent").innerHTML = str;
            // Add event modifier to forms \todo better way!
            if (str.indexOf('form.html?form=userForm&') !== -1) {
                const formElement = document.getElementById('userDataForm');
                if (formElement) {
                    formElement.addEventListener('submit', async (e) => {
                        let result = await window.formEventListener(e);
                        if (result.result && result.result !== '') {
                            document.getElementById("targetContent").innerHTML = result.result;
                        } else if (result.resultData && result.resultData  !== '') {
                            document.getElementById("targetContent").innerHTML = result.resultData;
                        } else if (result.status) {
                            document.getElementById("targetContent").innerHTML = "Status: " + result.status;
                        } else {
                            document.getElementById("targetContent").innerHTML = "Form result: Unknown!";;
                        }
                    });
                }
            }
        });
    }

    onDocTargetClick (projectName, name) {
        const server = this.props.server;
        server.executeProjectDocTarget (projectName, name, (data) => {
            document.getElementById("targetContent").innerHTML = data;
        });
    }

    onInfoTargetClick (projectName, name) {
        const server = this.props.server;
        server.executeProjectInfoTarget (projectName, name, (data) => {
            document.getElementById("targetContent").innerHTML = "<pre>" + data + "</pre>";
        });
    }

    viewName (name) {
        return name.substr(name.lastIndexOf(':')+1);
    }

    componentDidUpdate() {
        document.getElementById("targetContent").innerHTML = "";
    }

    render() {
        const { t } = this.props;
        const projectName = this.props.projectName;
        var items = this.props.items;
        if (!items.dynamic || !items.target || !items.info || !items.tests || !items.doc || !items.clean) {
            console.log("TargetList: invalid list data!");
            return null;
        }
        items.dynamic.sort();
        items.target.sort();
        items.info.sort();
        items.tests.sort();
        items.doc.sort();
        items.clean.sort();
        const dynamic = items.dynamic.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
        const targets = items.target.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
        const doc = items.doc.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onDocTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
        const info = items.info.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onInfoTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
        const tests = items.tests.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
        const clean = items.clean.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onTargetClick(projectName, name)}>
                <i className="file icon"></i>
                <div className="content">
                    <div className="header">{this.viewName(name)}</div>
                    <span className="ui small grey text">&lt;{t('target.empty')}&gt;</span>
                </div>
            </div>
        );
    return (
        <div className="ui small list">
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.Targets')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {targets}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.DynamicTargets')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {dynamic}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.Documentation')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {doc}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.InfoTarget')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {info}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.Tests')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {tests}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <i className="folder icon"></i>
                <div className="content">
                    <div className="header"> {t('target.Clean')} </div>
                     <div className="list">
                        <div className="ui small selection list">
                            {clean}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
  }
}


const TargetList = withTranslation()(TargetListClass);
export default TargetList;
