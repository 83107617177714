
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import {EditorView, basicSetup} from "codemirror"
import {EditorState, Compartment} from "@codemirror/state"
import {placeholder, keymap} from "@codemirror/view"
import {syntaxHighlighting} from "@codemirror/language"
import {indentWithTab} from "@codemirror/commands"
import {tags} from "@lezer/highlight"
import metalang from './grammar/metalang_language.js';
//-import metalangHighlightStyle from './grammar/metalang_language.js';

import ExampleList from './ExampleList';
import encodeXml from './utils.js';
import './ExampleLayout.css';


class ExampleLayoutClass extends React.Component {
    exampleList = null;
    editor = null;

    constructor(props) {
        super(props);
        this.fireTest = this.fireTest.bind(this);
        this.importClick = this.importClick.bind(this);
        this.fullscreen = this.fullscreen.bind(this);
        this.onfullscreenchange = this.onfullscreenchange.bind(this);
    }

    componentDidMount() {
        if (!this.editor) {
            const { t } = this.props;
            const placeholderText = t('example.placeholder');
            const codeExample = document.getElementById("codeExample");
            let language = new Compartment(), tabSize = new Compartment();

            let startState = EditorState.create({
                extensions: [
                    basicSetup,
                    placeholder(placeholderText),
                    language.of(metalang()),
                    keymap.of([indentWithTab, {key:"F11", run:this.fullscreen}]),
                    tabSize.of(EditorState.tabSize.of(4))
//-                    syntaxHighlighting(metalangHighlightStyle)
                ]
            });
            this.editor = new EditorView({
               state: startState,
                parent: codeExample
            });
        }

        if (!this.exampleList) {
            const container = document.getElementById('exampleList');
            this.exampleList = createRoot(container);
        }
        this.exampleList.render(
          <React.StrictMode>
            <ExampleList editor={this.editor} />
          </React.StrictMode>
        );
    }

    importClick() {
        document.getElementById("loadExample").click();
    }

    uploadFile = event => {
        if (event.target.files.length === 0)
            return;
        const file = event.target.files[0];
        let reader = new FileReader ();
        reader.onload = (e) => {
            const file = e.target.result;
            const lines = file.split(/\r\n|\n/);
            this.editor.dispatch({changes: {from: 0, to: this.editor.state.doc.length, insert: lines.join('\n')}});
        }
        reader.onerror = (e) => alert(e.target.error.name);
        reader.readAsText(file);
    }

    fireTest() {
        const code = this.editor.state.doc.toString();
        if (code === "") {
            document.getElementById("console").innerHTML = "";
            return;
        }
        const args = document.getElementById("args").value;
        const codeLang = document.getElementById("codeLang").value;
        document.getElementById("console").innerHTML = "";
        this.props.server.runCode (code, args, codeLang, (data) => {
            var str = "<div class='consoleResult'>";
            str += encodeXml(data.result);
            str += "</div>";
            if (data.error) {
                str += "<div class='consoleError'>";
                str += encodeXml(data.error);
                str += "</div>";
            } else if (data.status === false) {
                str += "<div class='consoleError'>Status: fail.</div>";
            }
            document.getElementById("console").innerHTML += str;
        });
    }

    fullscreen() {
        if (document.fullscreenEnabled) {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    document.onfullscreenchange = null;
                }
            } else {
                const codeExample = document.getElementById("codeExample");
                if (codeExample && codeExample.requestFullscreen) {
                    codeExample.requestFullscreen();
                    document.onfullscreenchange = this.onfullscreenchange;
                }
            }
        }
    }

    onfullscreenchange() {
        const codeExample = document.getElementById("codeExample");
        if (document.fullscreenElement)
            codeExample.firstChild.style.maxHeight="none"
        else
            codeExample.firstChild.style.maxHeight="49vh"
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="exampleLayout">
              <div className="ui grid">
                  <div className="two column row">
                    <div className="three wide column">
                        <div id="exampleList"></div>
                    </div>
                    <div className="thirteen wide stretched column">
                        <div className="row codeBox" id="codeExample"></div>
                        <div className="row codeControl ui container">
                            <div className="ui labeled input">
                                <div className="ui label"> {t('example.Args')}: </div>
                                <input id="args" type="text" spellCheck="false" placeholder="<Optional>"></input>
                            </div>
                            <div className="ui labeled input">
                                <div className="ui label"> {t('example.Language')}:</div>
                                <select id="codeLang" className="ui selection dropdown">
                                    <option value="metalang-runtime"> {t('example.LangRuntime')} </option>
                                    <option value="metalang-full"> {t('example.LangFull')} </option>
                                </select>
                            </div>
                            <button className="ui green button" id="fire" onClick={this.fireTest}> {t('example.Execute')} </button>
                            <div className="ui labeled input">
                                <button className="ui button codeControl" onClick={this.importClick}> {t('example.ImportFile')} </button>
                                <input className="ui button codeControl" id="loadExample" type="file" hidden onChange={this.uploadFile}></input>
                            </div>
                            <button className="ui right floated icon button" onClick={this.fullscreen} data-tooltip="Fullscreen editor" data-position="top right">
                                <i className="expand arrows alternate icon"></i>
                            </button>
                        </div>
                        <div className="row consoleBox">
                            <div className="console" id="console"></div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

const ExampleLayout = withTranslation()(ExampleLayoutClass);
export default ExampleLayout;
