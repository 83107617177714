
import React from 'react';
import { withTranslation } from 'react-i18next';


class OntologySelectClass extends React.Component {
    render() {
        const { t } = this.props;
        const items = this.props.items;
        const elements = items.names.map((name, index) =>
            <option key={index}>{name}</option>
        );
        return (
            <select id="ontologyName" className="ui selection dropdown">
                <option value="<all>">&lt;{t('generic.All')}&gt;</option>
                {elements}
            </select>
        );
    }
}

const OntologySelect = withTranslation()(OntologySelectClass);
export default OntologySelect;