

window.showLoader ();


class Server {
    constructor () {
        this.mode = 'webassembly';
        this.myClass = null;
        this.frontendInit = null;
        this.frontendUpdate = null;
    }

    console_debug (message) {
        if (typeof window !== 'undefined')
            console.debug(message);
    }

    initializeFrontend (handler) {
        this.frontendInit = handler;
    }
    updateFrontend (handler) {
        this.frontendUpdate = handler;
    }

    shutdown () {
        this.console_debug("shutdown()");
        if (this.myClass.finalize() === false) {
            alert ("Cannot shut down the WASM content!");
        }
        this.myClass.delete ();
        this.myClass = null;
    }

    importPackagePath (url, path) {
        this.console_debug("importPackagePath(): "+url);
        this.importPackagePathFetch (url, path)
        .catch((error) => {
            console.log ("importPackagePath() error: "+error);
        });
    }

    async importPackagePathFetch (url, path) {
        let response = await fetch(url+"/files.json")
        if (!response.ok)
            throw new Error ("importPackagePath() failed!");
        let data = await response.json();
        for (var i of data) {
            await this.importPackageFile (url, path, i)
        }

        this.addExternalPath (path, () => {
            if (this.frontendUpdate)
                this.frontendUpdate ();
        });
    }

    async importPackageFile (url, path, i) {
        this.console_debug("importPackageFile(): "+url+"/"+i);
        let response = await fetch (url+"/"+i)
        if (!response.ok)
            throw new Error ("importPackagePath() failed!");
        let buffer = await response.arrayBuffer();
        let data = new Int8Array (buffer);
        window.writeImportedFile (path+"/"+i, data);
    }

    updateProjectList (handler) {
        this.console_debug("updateProjectList()");
        var data = this.myClass.listProjects ();
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateProjectInfoTarget (handler) {
        this.console_debug("updateProjectInfoTarget()");
        var data = this.myClass.listProjects ();
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateProjectTargets (projectName, handler) {
        this.console_debug("updateProjectTargets()");
        if (projectName === '')
            return;
        var data = this.myClass.listProjectTasks (projectName);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateContextList (handler) {
        this.console_debug("updateContextList()");
        var data = this.myClass.listContexts ();
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateRepositoryList (handler) {
        this.console_debug("updateRepositoryList()");
        var data = this.myClass.listRepositories ();
        if (data === "")
            return;

        handler (JSON.parse (data));
    }

    updateValueList (name, handler) {
        if (name === '')
            return;
        var data = this.myClass.getGlobalValueData (name);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateEvaluatedPath (name, handler) {
        if (name === '')
            return;
        var data = this.myClass.getEvaluatedPath (name);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    executeProjectDocTarget (projectName, target, handler) {
        if (projectName === '')
            return;

        this.console_debug("executeProjectDocTarget()"+target);
        var data = this.myClass.callProjectDocTarget (projectName, target);
        if (data === "")
            return;

        handler (data);
    }

    executeProjectInfoTarget (projectName, target, handler) {
        if (projectName === '')
            return;

        this.console_debug("executeProjectInfoTarget()"+target);
        var data = this.myClass.callProjectInfoTarget (projectName, target);
        if (data === "")
            return;

        handler (data);
    }

    executeProjectTarget (projectName, target, handler) {
        if (projectName === '')
            return;

        this.console_debug("executeProjectTarget(): "+target);
        try {
            var data = this.myClass.callProjectTarget (projectName, target);
            if (data === "")
                return;

            handler (JSON.parse(data));
        }
        catch (error) {
            console.log (error);
            return;
        }
    }

    updateProjectItems (projectName, path, handler) {
        if (projectName === '')
            return;

        this.console_debug("updateProjectItems()");
        var data = this.myClass.listProjectPath (projectName, path);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateProjectContent (projectName, filename, handler) {
        if (projectName === '')
            return;

        this.console_debug("updateProjectContent()");
        var data = this.myClass.getProjectFile (projectName, filename);

        handler (data);
    }

    updatePackageItems (handler) {
        this.console_debug("updatePackageItems()");
        var data = this.myClass.listPackageNames ();
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updatePackageDetails (name, handler) {
        if (name === '')
            return;
        this.console_debug("updatePackageDetails()");
        var data = this.myClass.getPackageInfo (name);
        if (data === "")
            return;

        handler (data);
    }

    updateRepositoryItems (handler) {
        this.console_debug("updateRepositoryItems()");
        var data = this.myClass.listRepositories ();
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    updateRepositoryDetails (name, handler) {
        if (name === '')
            return;
        this.console_debug("updateRepositoryDetails()");
        var data = this.myClass.getRepositoryDetails (name);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    loadProject (path, handler) {
        this.console_debug("loadProject()");
        var data = this.myClass.loadProject (path);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    loadRemoteProject (path, handler) {
        this.console_debug("loadRemoteProject()");
        if (this.myClass.loadRemoteProject(path) === true) {
            handler ("Remote project is loaded successfully.");
        }
        else {
            handler ("Cannot load remote package!");
        }

    }

    closeProject (projectName, handler) {
        if (projectName === '')
            return;

        this.console_debug("closeProject()");
        if (this.myClass.closeProject(projectName) === false) {
            alert ("Cannot close package!");
        }

        handler ();
    }

    addLocationUrl (url, handler) {
        this.console_debug("addLocationUrl()");
        if (this.myClass.addRemoteLocation(url) === false) {
            alert ("Cannot add "+url+" remote location!");
        }

        handler ();
    }

    addExternalPath (path, handler) {
        this.console_debug("addExternalPath()");
        if (this.myClass.addExternalPath(path) === false) {
            alert ("Cannot add "+path+" external path!");
        }

        handler ();
    }

    importProjectData (packageData, handler) {
        this.console_debug("importProjectData()");
        var data = this.myClass.importProjectData(new Int8Array(packageData));
        handler (JSON.parse(data));
    }

    repositoryQuery (name, type, handler) {
        if (name === '')
            return;
        this.console_debug("repositoryQuery()");
        var data = this.myClass.repositoryQuery (name, type);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    ontologyQuery (name, type, format, handler) {
        this.console_debug("ontologyQuery()");
        var data = this.myClass.ontologyQuery (name, type, format);
        if (data === "")
            return;

        handler (JSON.parse(data));
    }

    runCode(code, args, language, handler) {
        this.console_debug("fireTest()");
        document.getElementById("console").innerHTML = "";
        var data = this.myClass.executeCode (code, args, language);
        if (data === "") {
            console.log ( "executeBuffer(): Internal error!");
        }
        else {
            handler (JSON.parse(data));
        }
    }
}

export default Server;
