import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { ENGLISH } from "./translatons/en";
import { HUNGARIAN } from "./translatons/hu";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en",
        resources: {
            en: {
                translation: ENGLISH
            },
            hu: {
                translation: HUNGARIAN
            }
        }
    });

//-i18n.changeLanguage("hu");
