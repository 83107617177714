// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
const spec_Identifier = {__proto__:null,true:62, false:64, namespace:22, workspace:24, alias:26, import:28, import_package:30, class:32, representation:34, resource:36, transform:38, key:40, pattern:42, slot:44, slotuse:46, function:48, method:50, entrypoint:52}
export const parser = LRParser.deserialize({
  version: 14,
  states: "!QQ]QPOOOOQO'#Ce'#CeOOQO'#Ca'#CaOOQO'#Cf'#CfOOQO'#C`'#C`OOQO'#Cw'#CwQ]QPOOOOQO-E6u-E6u",
  stateData: "!y~OnOSPOSQOS~OUQOVQOWQOZRO[RO]RO^RO_RO`ROaRObROcROdROeROfROgROhROiROjROoPOpPOqROrRO~OPQ~",
  goto: "!PlPPPPmqPPPuqPPPPPPPPPPPPPPPPyTTOUTSOUTQOUQUORVU",
  nodeNames: "⚠ LineComment BlockComment Program Element Expression Identifier Number String Boolean Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword Keyword",
  maxTerm: 34,
  skippedNodes: [0,1,2],
  repeatNodeCount: 1,
  tokenData: ")S~RZXYtYZt]^tpqtrs!Vz{#s!P!Q&[!Q![(T!c!}(h#R#S(h#T#o(h~ySn~XYtYZt]^tpqt~!YVOr!Vrs!os#O!V#O#P!t#P;'S!V;'S;=`#m<%lO!V~!tOW~~!wRO;'S!V;'S;=`#Q;=`O!V~#TWOr!Vrs!os#O!V#O#P!t#P;'S!V;'S;=`#m;=`<%l!V<%lO!V~#pP;=`<%l!V~#vQ#b#c#|#k#l%T~$PP#T#U$S~$VP#a#b$Y~$]P#X#Y$`~$cP#g#h$f~$iP#d#e$l~$oP#T#U$r~$uP#V#W$x~${P#X#Y%O~%TOq~~%WP#c#d%Z~%^P#f#g%a~%dP#_#`%g~%jP#g#h%m~%pP#d#e%s~%vP#T#U%y~%|P#V#W&P~&SP#X#Y&V~&[Or~~&_Qz{&e!P!Q'l~&hTOz&ez{&w{;'S&e;'S;=`'f<%lO&e~&zVOz&ez{&w{!P&e!P!Q'a!Q;'S&e;'S;=`'f<%lO&e~'fOQ~~'iP;=`<%l&e~'qSP~OY'lZ;'S'l;'S;=`'}<%lO'l~(QP;=`<%l'l~(YQV~!O!P(`!Q![(T~(ePV~!Q![(`~(mVU~!Q![(h![!](h!c!}(h#R#S(h#T#o(h#o#p(h#q#r(h",
  tokenizers: [0],
  topRules: {"Program":[0,3]},
  specialized: [{term: 6, get: value => spec_Identifier[value] || -1}],
  tokenPrec: 84
})

