
import React, { Suspense } from "react";

import "./i18nConfig";
import MainMenu from './MainMenu';
import SideMenu from './SideMenu';
import WelcomeLayout from './WelcomeLayout';
import ExampleLayout from './ExampleLayout';
import ProjectLayout from './ProjectLayout';
import TargetLayout from './TargetLayout';
import OntologyLayout from './OntologyLayout';
import QueryLayout from './QueryLayout';
import DocLayout from './DocLayout';
import RepositoryLayout from './RepositoryLayout';
import ContentLayout from './ContentLayout';
import EngineRoom from './EngineRoom';
import Loader from './Loader';
import LoadDialog from './LoadDialog';
import CloseDialog from './CloseDialog';
import AddLocationDialog from './AddLocationDialog';
import AboutDialog from './AboutDialog';
import ShutdownDialog from './ShutdownDialog';
import LicenseDialog from './LicenseDialog';


class App extends React.Component {
    componentDidMount() {
        window.pageInit();
    }

    render() {
        const server = this.props.server;
        return (
            <div className="mainwindow">
                <Suspense fallback={null}>
                    <MainMenu />
                    <div className="ui equal height stretched grid">
                        <div className="two wide column" id="sidemenu">
                            <SideMenu />
                        </div>
                        <div className="fourteen wide stretched column">
                            <div className="ui tab segment"></div>
                            <WelcomeLayout />
                            <ExampleLayout server={server} />
                            <ProjectLayout server={server} />
                            <TargetLayout server={server} />
                            <OntologyLayout server={server} />
                            <QueryLayout server={server} />
                            <DocLayout />
                            <RepositoryLayout server={server} />
                            <ContentLayout server={server} />
                            <EngineRoom server={server} />
                        </div>
                    </div>
                    <Loader />
                    <LoadDialog server={server} />
                    <CloseDialog server={server} />
                    <AddLocationDialog />
                    <AboutDialog />
                    <ShutdownDialog />
                    <LicenseDialog />
                </Suspense>
            </div>
        );
    }
}

export default App;

