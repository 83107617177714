
import React from 'react';
import { withTranslation } from 'react-i18next';


class QueryTableClass extends React.Component {
    render() {
        const { t } = this.props;
        const items = this.props.items;
        const elements = items.map((o, index) =>
            <tr key={index}>
                <td data-label="Name">{o.name}</td>
                <td data-label="Type">{o.type}</td>
                <td data-label="Repository">{o.repository}</td>
                <td data-label="Package">{o.pkg}</td>
                <td data-label="Resource">{o.resource}</td>
            </tr>
        );
        return (
            <table className="ui celled table">
                <thead><tr>
                    <th> {t('query.Name')} </th>
                    <th> {t('query.Type')} </th>
                    <th> {t('query.Repository')} </th>
                    <th> {t('query.Package')} </th>
                    <th> {t('query.Resource')} </th>
                </tr></thead>
                <tbody>
                    {elements}
                </tbody>
            </table>
        );
    }
}

const QueryTable = withTranslation()(QueryTableClass);
export default QueryTable;