
import React from 'react';
import { useTranslation } from "react-i18next";


const AddLocationDialog = () => {
    const { t } = useTranslation();
    return (
        <div className="ui modal" id="addLoactionDialog">
            <div className="header"> {t('dialog.AddLocation')} </div>
            <div className="content">
                <div className="ui input">
                    <input id="addLocationData" type="text" placeholder="<URL>" size="40"></input>
                </div>
            </div>
            <div className="actions">
                <div className="ui ok button"> {t('dialog.Ok')} </div>
                <div className="ui cancel button"> {t('dialog.Cancel')} </div>
            </div>
        </div>
    );
};

export default AddLocationDialog;