
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import ProjectContent from './ProjectContent';


class ProjectListClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: {packages: [], projects: [], resources: [], remote: []}
        };
        this.onProjectClick = this.onProjectClick.bind(this);
        this.onProjectRemoteClick = this.onProjectRemoteClick.bind(this);
    }

    onProjectClick(server, name) {
        server.updatePackageDetails (name, (details) => {
            if (!RootData.projectContent) {
                const container = document.getElementById('projectContent');
                RootData.projectContent = createRoot(container);
            }
            RootData.projectContent.render(
                <React.StrictMode>
                    <ProjectContent server={server} details={details} />
                </React.StrictMode>
            );
        });
    }

    onProjectRemoteClick(server, name) {
        if (!this.projectContent) {
            const container = document.getElementById('projectContent');
            this.projectContent = createRoot(container);
        }
        this.projectContent.render(
            <React.StrictMode>
                <ProjectContent server={server} remote={name} />
            </React.StrictMode>
        );
    }

    componentDidMount() {
        const server = this.props.server;
        server.updatePackageItems ((data) => {
            this.setState({
                isLoaded: true,
                items: data
            });
            if (data.projects && (data.projects.length !== 0)) {
                this.onProjectClick (server, data.projects[0]);
            }
            else if (data.packages.length !== 0) {
                this.onProjectClick (server, data.packages[0]);
            }
        });
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return <div>{t('generic.Loading')}</div>;
        }
        var items;
        if (this.props.items)
            items = this.props.items;
        else
            items = this.state.items;
        items.projects.sort();
        items.resources.sort();
        items.packages.sort();
        items.remote.sort();
        const server = this.props.server;
        const projects = items.projects.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onProjectClick(server, name)}>
                <i className="project diagram icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                    {t('project.ProjectDescripion')}
                </div>
            </div>
        );
        const resources = items.resources.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onProjectClick(server, name)}>
                <i className="project diagram icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                    {t('project.ProjectDescripion')}
                </div>
            </div>
        );
        const packages = items.packages.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onProjectClick(server, name)}>
                <i className="project diagram icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                    {t('project.ProjectDescripion')}
                </div>
            </div>
        );
        const remotes = items.remote.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onProjectRemoteClick(server, name)}>
                <i className="project diagram icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                    {t('project.ProjectDescripion')}
                </div>
            </div>
        );
        return (
            <div className="ui small list">
                <div className="item">
                    <i className="folder icon"></i>
                    <div className="content">
                        <div className="header"> {t('project.Projects')} </div>
                         <div className="list">
                            <div className="ui small selection list">
                                {projects}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <i className="folder icon"></i>
                    <div className="content">
                        <div className="header"> {t('project.Packages')} </div>
                         <div className="list">
                            <div className="ui small selection list">
                                {packages}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <i className="folder icon"></i>
                    <div className="content">
                        <div className="header"> {t('project.Resources')} </div>
                         <div className="list">
                            <div className="ui small selection list">
                                {resources}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <i className="folder icon"></i>
                    <div className="content">
                        <div className="header"> {t('project.Remote')} </div>
                         <div className="list">
                            <div className="ui small selection list">
                                {remotes}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ProjectList = withTranslation()(ProjectListClass);
export default ProjectList;
