
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import QuerySelect from './QuerySelect';
import QueryTable from './QueryTable';


class QueryLayoutClass extends React.Component {
    static queryTable = null;

    constructor(props) {
        super(props);
        this.queryClear = this.queryClear.bind(this);
        this.querySubmit = this.querySubmit.bind(this);
    }

    queryClear() {
        var empty = [];
        const server = this.props.server;
        QueryLayoutClass.queryTable.render(
            <React.StrictMode>
                <QueryTable server={server} items={empty} />
            </React.StrictMode>
        );
    }

    querySubmit() {
        var queryName = document.getElementById("queryName");
        if (!queryName)
            return;
        var queryType = document.getElementById("queryType");
        if (!queryType)
            return;
        const name = queryName.value;
        const type = queryType.value;
        const server = this.props.server;
        server.repositoryQuery (name, type, (data) => {
            if (!QueryLayoutClass.queryTable) {
                const container = document.getElementById('queryTable');
                QueryLayoutClass.queryTable = createRoot(container);
            }
            QueryLayoutClass.queryTable.render(
                <React.StrictMode>
                    <QueryTable server={server} items={data} />
                </React.StrictMode>
            );
        });
    }

    componentDidMount() {
        const server = this.props.server;
        if (!RootData.querySelect) {
            const container = document.getElementById('querySelect');
            RootData.querySelect = createRoot(container);
        }
        var empty = { names: [] };
        RootData.querySelect.render(
          <React.StrictMode>
            <QuerySelect server={server} items={empty} />
          </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="queryLayout">
                <div className="row">
                    <div className="ui labeled input">
                        <div className="ui label"> {t('query.NodeType')}:</div>
                        <select id="queryType" className="ui select">
                            <option value="<all>">&lt;{t('generic.All')}&gt;</option>
                            <option value="resource"> {t('query.Resource')} </option>
                            <option value="class"> {t('query.Class')} </option>
                            <option value="function"> {t('query.Function')} </option>
                            <option value="representation"> {t('query.Representation')} </option>
                            <option value="transform"> {t('query.Transform')} </option>
                        </select>
                    </div>
                    <div className="ui labeled input queryPadding">
                        <div className="ui label"> {t('query.Repository')}: </div>
                        <div id="querySelect"></div>
                    </div>
                    <button className="ui green button" type="submit" onClick={this.querySubmit}> {t('query.Query')} </button>
                    <button className="ui orange button" type="submit" onClick={this.queryClear}> {t('query.Clear')} </button>
                </div>
                <div id="queryTable"></div>
            </div>
        );
    }
}

const QueryLayout = withTranslation()(QueryLayoutClass);
export default QueryLayout;
