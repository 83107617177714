import {parser} from "./metalang.js"
import {styleTags, tags} from "@lezer/highlight"
import {LRLanguage} from "@codemirror/language"
import {completeFromList} from "@codemirror/autocomplete"
import {HighlightStyle, LanguageSupport} from "@codemirror/language"

let parserWithMetadata = parser.configure({
  props: [
    styleTags({
      Identifier: tags.variableName,
      Number: tags.number,
      Boolean: tags.bool,
      String: tags.string,
      Keyword: tags.keyword,
      BlockComment: tags.blockComment,
      LineComment: tags.lineComment,
      "[ ]": tags.squareBracket,
      "{ }": tags.brace
    })
  ]
})


const metalangLanguage = LRLanguage.define({
  parser: parserWithMetadata,
  languageData: {
    commentTokens: {line: "//"}
  }
})


const metalangCompletion = metalangLanguage.data.of({
  autocomplete: completeFromList([
    {label: "*namespace", type: "keyword"},
    {label: "namespace", type: "keyword"},
    {label: "*workspace", type: "keyword"},
    {label: "workspace", type: "keyword"},
    {label: "*alias", type: "keyword"},
    {label: "alias", type: "keyword"},
    {label: "*import", type: "keyword"},
    {label: "import", type: "keyword"},
    {label: "*import_package", type: "keyword"},
    {label: "import_package", type: "keyword"},
    {label: "*class", type: "keyword"},
    {label: "class", type: "keyword"},
    {label: "*representation", type: "keyword"},
    {label: "representation", type: "keyword"},
    {label: "*metadata", type: "keyword"},
    {label: "metadata", type: "keyword"},
    {label: "*transform", type: "keyword"},
    {label: "transform", type: "keyword"},
    {label: "*function", type: "keyword"},
    {label: "function", type: "keyword"},
    {label: "*method", type: "keyword"},
    {label: "method", type: "keyword"},
    {label: "*entrypoint", type: "keyword"},
    {label: "entrypoint", type: "keyword"},
    {label: "*if", type: "keyword"},
    {label: "if", type: "keyword"},
    {label: "*for", type: "keyword"},
    {label: "for", type: "keyword"},
    {label: "*foreach", type: "keyword"},
    {label: "foreach", type: "keyword"},
    {label: "*while", type: "keyword"},
    {label: "while", type: "keyword"},
    {label: "*continue", type: "keyword"},
    {label: "continue", type: "keyword"},
    {label: "*break", type: "keyword"},
    {label: "break", type: "keyword"},
    {label: "true", type: "constant"},
    {label: "false", type: "constant"}
  ])
})

export const metalangHighlightStyle = HighlightStyle.define([
  {tag: tags.keyword, color: "#fc6"},
  {tag: tags.string, color: "#fc6"},
  {tag: tags.variableName, color: "#fbf"},
  {tag: tags.comment, color: "#f5d", fontStyle: "italic"}
])

export default function metalang() {
  return new LanguageSupport(metalangLanguage, [metalangCompletion])
}
