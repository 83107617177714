
export const HUNGARIAN = {
    side:  {
        "Welcome": "Üdvözlet",
        "Examples": "Példák",
        "Projects": "Projektek",
        "Content": "Tartalom",
        "Targets": "Célok",
        "Ontology": "Ontologia",
        "Query": "Lekérdezés",
        "Repositories": "Raktátak",
        "Documentation": "Dokumentáció",
        "EngineRoom": "Gépház"
    },
    menu: {
        "Project": "Projekt",
        "Load": "Betöltés",
        "Close": "Bezárás",
        "Import": "Importálás",
        "AddLocation": "Hely hozzáadása",
        "Help": "Segítség",
        "License": "Licenc",
        "About": "Névjegy",
        "tp": "technológiai előzetes"
    },
    engine: {
        "Values": "Értékek",
        "Paths": "Útvonalak",
        "Context": "Kontextus",
        "GlobalRefresh": "Globális frissítés"
    },
    example: {
        "Args": "Argumentumok",
        "Execute": "Futtatás",
        "ImportFile": "Fájl importálása",
        "placeholder": "Példa kód... (Gombnyomásra teljes képernyő)",
        "Language": "Nyelv",
        "LangRuntime": "Futásidejű",
        "LangFull": "Teljes"
    },
    dialog: {
        "Ok": "Ok",
        "Cancel": "Mégsem",
        "Yes": "Igen",
        "No": "Nem",
        "Close": "Bezár",
        "About": "Névjegy",
        "AddLocation": "Hely hozzáadása",
        "CloseProject": "Projekt bezárása",
        "License": "Licenc",
        "LoadProject": "Projekt betöltése",
        "Loading": "Betöltés..",
        "Shutdown": "Leállítás",
        "Sure": "Biztos benne?",
        "testing": "Csak tesztelés engedélyezett ezen az oldalon.",
        "store": "Ez az oldal nem gyűjt vagy tárol adatokat."
    },
    content: {
        "select": "Válassz ki projektet és tartalmat először."
    },
    doc: {
        "manual": "Programnyelv útmutató",
        "package": "Csomag dokumentáció (Folyamatban)"
    },
    project: {
        "select": "Válassz ki projektet először.",
        "PackageName": "Csomag neve",
        "CloseRemote": "Távoli csomag bezárása",
        "LoadRemote": "Távoli csomag betöltése",
        "Close": "Bezárás",
        "Load": "Betöltés",
        "Name": "Név",
        "Description": "Leírás",
        "ID": "ID",
        "Type": "Típus",
        "Path": "Útvonal",
        "License": "Licenc",
        "Version": "Verzió",
        "Timestamp": "Időbélyeg",
        "Provides": "Szolgáltat",
        "Requires": "Igényel",
        "ProjectDescripion": "Projekt leírás.",
        "Projects": "Projektek",
        "Packages": "Csomagok",
        "Resources": "Erőforrások",
        "Remote": "Távoli"
    },
    ontology: {
        "Resources": "Erőforrások",
        "Packages": "Csomagok",
        "OpenedProjects": "Megnyitott projektek",
        "Representations": "Reprezentációk",
        "Workspaces": "Munkaterek",
        "Repository": "Raktár",
        "Format": "Formátum",
        "Query": "Lekérdezés",
        "Clear": "Törlés",
        "Selector": "Kiválasztó"
    },
    query: {
        "NodeType": "Csomópunt típus",
        "Resource": "Erőforrás",
        "Class": "Osztály",
        "Function": "Funkció",
        "Representation": "Reprezentáció",
        "Transform": "Transzformáció",
        "Repository": "Raktár",
        "Query": "Lekérdez",
        "Clear": "Törlés",
        "Name": "Név",
        "Type": "Típus",
        "Package": "Csomag"
    },
    repo: {
        "select": "Válassz ki raktárat először.",
        "Name": "Név",
        "ContentSize": "Tartalom mérete",
        "ImportedPackages": "Importált csomagok",
        "Namespaces": "Névterek",
        "Workspaces": "Munkaterek"
    },
    target: {
        "select": "Válassz ki projektet és célt először.",
        "empty": "üres",
        "Targets": "Célok",
        "DynamicTargets": "Dinamikus célok",
        "Documentation": "Dokumentáció",
        "InfoTarget": "Információk",
        "Tests": "Tesztek",
        "Clean": "Tisztítás"
    },
    welcome: {
        "title": "Meta/System technológiai előzetes",
        "intro": "Tudásreprezentáció alapú fejlesztői környezet.",
        "About": "Névjegy",
        "about": "Névjegy.",
        "Usage": "Használat",
        "usage": "A példák nevű oldalsó menüben egyszerűbb kódrészletek találhatóak. Összetett példák projektbe csomagolva érhetők el a projektek menüben, vagy a fenti menüből importálva. A többi menüpont a betoltött projektek kezelésére haszálható."
    },
    generic: {
        "Loading": "Betöltés...",
        "All": "Mind"
    }
};

