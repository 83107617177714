
import React from 'react';
import { withTranslation } from 'react-i18next';


class LoadSelectClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: []
        };
    }

    componentDidMount() {
        this.props.server.updatePackageItems ((data) => {
            this.setState({
                isLoaded: true,
                items: data.remote
            });
        });
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return <div> {t('generic.Loading')} </div>;
        }
        var items;
        if (this.props.items)
            items = this.props.items.remote;
        else
            items = this.state.items;
        items.sort();
        const elements = items.map((name, index) =>
            <option key={index}>{name}</option>
        );
    return (
        <select id="loadSelectData" className="ui selection dropdown">
            {elements}
        </select>
      );
  }
}

const LoadSelect = withTranslation()(LoadSelectClass);
export default LoadSelect;
