
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import TargetList from './TargetList';


class TargetSelectClass extends React.Component {
    static targetList = null;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: {names: []}
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        var targetSelectData = document.getElementById("targetSelectData")
        if (!targetSelectData)
            return;
        const projectName = targetSelectData.value;
        var server = this.props.server;
        if (projectName === "") {
            const data = {doc: [], target: [], dynamic: [], info: [], clean: [], tests: []};
            if (!TargetSelectClass.targetList) {
                const container = document.getElementById('targetList');
                TargetSelectClass.targetList = createRoot(container);
            }
            TargetSelectClass.targetList.render(
              <React.StrictMode>
                <TargetList server={server} projectName={""} items={data} />
              </React.StrictMode>
            );
            return;
        }
        this.props.server.updateProjectTargets (projectName, (data) => {
            if (!TargetSelectClass.targetList) {
                const container = document.getElementById('targetList');
                TargetSelectClass.targetList = createRoot(container);
            }
            TargetSelectClass.targetList.render(
              <React.StrictMode>
                <TargetList server={server} projectName={projectName} items={data} />
              </React.StrictMode>
            );
        });
    }

    componentDidMount() {
        var server = this.props.server;
        server.updateProjectList ((data) => {
            this.setState({
                isLoaded: true,
                items: data
            });
            if (data.names.length !== 0) {
                const projectName = data.names[0];
                this.props.server.updateProjectTargets (projectName, (data) => {
                    if (!TargetSelectClass.targetList) {
                        const container = document.getElementById('targetList');
                        TargetSelectClass.targetList = createRoot(container);
                    }
                    TargetSelectClass.targetList.render(
                      <React.StrictMode>
                        <TargetList server={server} projectName={projectName} items={data} />
                      </React.StrictMode>
                    );
                });
            }
        });
    }

    componentDidUpdate() {
        this.onChange();
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return <div> {t('generic.Loading')} </div>;
        }
        var items;
        if (this.props.items)
            items = this.props.items;
        else
            items = this.state.items;
        items.names.sort();
        const elements = items.names.map((name, index) =>
            <option key={index}>{name}</option>
        );
        return (
            <select id="targetSelectData" className="ui selection dropdown" onChange={this.onChange}>
                {elements}
            </select>
        );
  }
}

const TargetSelect = withTranslation()(TargetSelectClass);
export default TargetSelect;
