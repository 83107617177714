
import React from 'react';
import { useTranslation } from "react-i18next";


const WelcomeLayout = () => {
  const { t } = useTranslation();
    return (
        <div className="ui active tab segment" data-tab="welcomeLayout">
            <h1 className="ui centered header"> {t('welcome.title')} </h1>
            <p> {t('welcome.intro')} </p>
            <h2 className="ui header"> {t('welcome.Usage')} </h2>
            <p> {t('welcome.usage')} </p>
        </div>
    );
};

/*
            <h2 className="ui header"> {t('welcome.About')} </h2>
            <p> {t('welcome.about')} </p>
*/

export default WelcomeLayout;
