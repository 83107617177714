
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import ContentSelect from './ContentSelect';


class ContentLayoutClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (!RootData.contentSelect) {
            const container = document.getElementById('contentSelect');
            RootData.contentSelect = createRoot(container);
        }
        RootData.contentSelect.render(
          <React.StrictMode>
            <ContentSelect server={server} />
          </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="contentLayout">
                <div className="ui grid">
                    <div className="two column row">
                        <div className="four wide column">
                            <div className="right labelled input">
                                <div id="contentSelect"></div>
                            </div>
                            <div id="contentList"></div>
                        </div>
                        <div className="twelve wide stretched column">
                            <div id="fileContent"><span className="ui small grey text">&lt;{t('content.select')}&gt;</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const ContentLayout = withTranslation()(ContentLayoutClass);
export default ContentLayout;
