
import React from 'react';
import { createRoot } from 'react-dom/client';

import RepositoryContent from './RepositoryContent';


class RepositoryList extends React.Component {
    static repositoryContent = null;

    constructor(props) {
        super(props);
        this.onRepositoryClick = this.onRepositoryClick.bind(this);
    }

    onRepositoryClick(name) {
        const server = this.props.server;
        server.updateRepositoryDetails (name, (data) => {
            if (!RepositoryList.repositoryContent) {
                const container = document.getElementById('repositoryContent');
                RepositoryList.repositoryContent = createRoot(container);
            }
            RepositoryList.repositoryContent.render(
              <React.StrictMode>
                <RepositoryContent data={data} />
              </React.StrictMode>
            );
        });
    }

    componentDidUpdate() {
        if (!RepositoryList.repositoryContent) {
            const container = document.getElementById('repositoryContent');
            RepositoryList.repositoryContent = createRoot(container);
        }
        RepositoryList.repositoryContent.render(
          <React.StrictMode>
            <RepositoryContent />
          </React.StrictMode>
        );
    }

    render() {
        const items = this.props.items;
        if (items.names.length === 0) {
            return (
                <span className="ui small grey text"> - Empty - </span>
            );
        }
        items.names.sort();
        const elements = items.names.map((name, index) =>
            <div key={index} className="item" onClick={() => this.onRepositoryClick(name)}>
                <i className="database icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                </div>
            </div>
        );
        return (
            <div className="ui small selection list">
                {elements}
            </div>
        );
    }
}

export default RepositoryList;