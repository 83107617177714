
import React from 'react';
import { useTranslation } from "react-i18next";


const ShutdownDialog = () => {
    const { t } = useTranslation();
    return (
        <div className="ui mini modal" id="shutdownDialog">
            <div className="header"> {t('dialog.Shutdown')} </div>
            <div className="content"> {t('dialog.Sure')} </div>
            <div className="actions">
                <div className="ui red approve labeled icon button"><i className="power off icon"></i> {t('dialog.Yes')} </div>
                <div className="ui deny button"> {t('dialog.No')} </div>
            </div>
        </div>
    );
};

export default ShutdownDialog;
