
import React from 'react';
import { useTranslation } from "react-i18next";

const SideMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="ui secondary vertical fluid pointing menu">
        <a className="active item" data-tab="welcomeLayout" href="#">
            {t("side.Welcome")}
        </a>
        <a className="item" data-tab="exampleLayout" href="#">
            {t("side.Examples")}
        </a>
        <a className="item" data-tab="projectLayout" href="#">
            {t("side.Projects")}
        </a>
        <a className="item" data-tab="contentLayout" href="#">
            {t("side.Content")}
        </a>
        <a className="item" data-tab="targetLayout" href="#">
            {t("side.Targets")}
        </a>
        <a className="item" data-tab="ontologyLayout" href="#">
            {t("side.Ontology")}
        </a>
        <a className="item" data-tab="queryLayout" href="#">
            {t("side.Query")}
        </a>
        <a className="item" data-tab="repositoryLayout" href="#">
            {t("side.Repositories")}
        </a>
        <a className="item" data-tab="docLayout" href="#">
            {t("side.Documentation")}
        </a>
        <a className="item" data-tab="engineRoom" href="#">
            <span className="ui small grey text"> {t("side.EngineRoom")} </span>
        </a>
    </div>
  );
};

export default SideMenu;
