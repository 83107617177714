
import React from 'react';
import { withTranslation } from 'react-i18next';

import updateFrontendContent from './update.js';


class EngineRoomClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            context: [],
            paths: [],
            base_path: "",
            packages_path: "",
            packagemodules_path: "",
            externalpackages_path: "",
            externalprojects_path: "",
            arch: "",
            os: ""
        };
        this.globalRefresh = this.globalRefresh.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    globalRefresh() {
        const server = this.props.server;
        server.updateProjectList ((data) => {
            updateFrontendContent (server, data);
        });
        this.refresh ();
    }

    refresh() {
        const server = this.props.server;
        server.updateValueList ("OS", (data) => {
            this.setState({
                os: data.value
            });
        });
        server.updateValueList ("arch", (data) => {
            this.setState({
                arch: data.value
            });
        });
        server.updateContextList ((data) => {
            this.setState({
                context: data.names
            });
        });
        server.updateEvaluatedPath ("${base_path}", (data) => {
            this.setState({
                base_path: data.value
            });
        });
        server.updateEvaluatedPath ("${packages_path}", (data) => {
            this.setState({
                packages_path: data.value
            });
        });
        server.updateEvaluatedPath ("${packagemodules_path}", (data) => {
            this.setState({
                packagemodules_path: data.value
            });
        });
        server.updateEvaluatedPath ("${externalpackages_path}", (data) => {
            this.setState({
                externalpackages_path: data.value
            });
        });
        server.updateEvaluatedPath ("${externalprojects_path}", (data) => {
            this.setState({
                externalprojects_path: data.value
            });
        });
    }

    componentDidMount() {
        this.refresh();
    }

    render() {
        const { t } = this.props;
        const context = this.state.context.map((name, index) =>
            <li key={index}>{name}</li>
        );
        return (
            <div className="ui tab segment" data-tab="engineRoom">
                {t("engine.Values")}:
                <ul>
                    <li>Arch: {this.state.arch}</li>
                    <li>OS: {this.state.os}</li>
                </ul>
                {t("engine.Paths")}:
                <ul>
                    <li>$&#123;base_path&#125;: {this.state.base_path}</li>
                    <li>$&#123;packages_path&#125;: {this.state.packages_path}</li>
                    <li>$&#123;packagemodules_path&#125;: {this.state.packagemodules_path}</li>
                    <li>$&#123;externalpackages_path&#125;: {this.state.externalpackages_path}</li>
                    <li>$&#123;externalprojects_path&#125;: {this.state.externalprojects_path}</li>
                </ul>
                {t("engine.Context")}:
                <ul>
                    {context}
                </ul>
                <button className="ui red button" onClick={this.globalRefresh}> {t("engine.GlobalRefresh")} </button>
            </div>
        );
    }
}

const EngineRoom = withTranslation()(EngineRoomClass);
export default EngineRoom;