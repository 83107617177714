
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import ContentList from './ContentList';


class ContentSelectClass extends React.Component {
    static contentList = null;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: {names: []}
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        var contentSelectData = document.getElementById("contentSelectData")
        if (!contentSelectData)
            return;
        const projectName = contentSelectData.value;
        var server = this.props.server;
        if (projectName === "") {
            const data = {files: []};
            if (!ContentSelectClass.contentList) {
                const container = document.getElementById('contentList');
                ContentSelectClass.contentList = createRoot(container);
            }
            ContentSelectClass.contentList.render(
                <React.StrictMode>
                    <ContentList server={server} items={data} projectName={""} />
                </React.StrictMode>
            );
            return;
        }
        server.updateProjectItems (projectName, "/", (data) => {
            if (!ContentSelectClass.contentList) {
                const container = document.getElementById('contentList');
                ContentSelectClass.contentList = createRoot(container);
            }
            ContentSelectClass.contentList.render(
                <React.StrictMode>
                    <ContentList server={server} items={data} projectName={projectName} />
                </React.StrictMode>
            );
        });
        document.getElementById("fileContent").innerHTML = "";
    }

    componentDidMount() {
        var server = this.props.server;
        server.updateProjectList ((data) => {
            this.setState({
                isLoaded: true,
                items: data
            });
            if (data.names.length !== 0) {
                const projectName = data.names[0];
                this.props.server.updateProjectItems (projectName, "/", (data) => {
                    if (!ContentSelectClass.contentList) {
                        const container = document.getElementById('contentList');
                        ContentSelectClass.contentList = createRoot(container);
                    }
                    ContentSelectClass.contentList.render(
                        <React.StrictMode>
                            <ContentList server={server} items={data} projectName={projectName} />
                        </React.StrictMode>
                    );
                });
            }
        });
    }

    componentDidUpdate() {
        this.onChange();
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return <div> {t('generic.Loading')} </div>;
        }
        var items;
        if (this.props.items)
            items = this.props.items;
        else
            items = this.state.items;
        items.names.sort();
        const elements = items.names.map((name, index) =>
            <option key={index}>{name}</option>
        );
        return (
            <select id="contentSelectData" className="ui selection dropdown" onChange={this.onChange}>
                {elements}
            </select>
         );
    }
}

const ContentSelect = withTranslation()(ContentSelectClass);
export default ContentSelect;