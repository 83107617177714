
import React from 'react';
import { useTranslation } from "react-i18next";


const Loader = () => {
    const { t } = useTranslation();
    return (
        <div className="ui modal" id="loader">
            <div className="content">
                <div className="ui segment">
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader"> {t('dialog.Loading')} </div>
                    </div>
                    <p className="loaderSpacer"></p>
                </div>
            </div>
        </div>
    );
};

export default Loader;
