
class RootData {
    static projectContent = null;
    static projectList = null;
    static targetSelect = null;
    static contentSelect = null;
    static repositoryList = null;
    static querySelect = null;
    static ontologySelect = null;
    static closeSelect = null;
    static loadSelect = null;
}

export default RootData;
