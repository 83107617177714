
import React from 'react';

import encodeXml from './utils.js';


class OntologyTable extends React.Component {
    render() {
        const result = this.props.items.result;
        return (
            <pre>{result}</pre>
        );
    }
}

export default OntologyTable;