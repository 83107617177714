
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import TargetSelect from './TargetSelect';


class TargetLayoutClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (!RootData.targetSelect) {
            const container = document.getElementById('targetSelect');
            RootData.targetSelect = createRoot(container);
        }
        RootData.targetSelect.render(
          <React.StrictMode>
            <TargetSelect server={server} />
          </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="targetLayout">
                <div className="ui grid">
                    <div className="two column row">
                        <div className="four wide column">
                            <div className="right labelled input">
                                <div id="targetSelect"></div>
                            </div>
                            <div id="targetList"></div>
                        </div>
                        <div className="twelve wide stretched column">
                            <div id="targetContent"><span className="ui small grey text">&lt;{t('target.select')}&gt;</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const TargetLayout = withTranslation()(TargetLayoutClass);
export default TargetLayout;
