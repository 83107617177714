
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import ProjectList from './ProjectList';


class ProjectLayoutClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (!RootData.projectList) {
            const container = document.getElementById('projectList');
            RootData.projectList = createRoot(container);
        }
        RootData.projectList.render(
          <React.StrictMode>
            <ProjectList server={server} />
          </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tab segment" data-tab="projectLayout">
              <div className="ui grid">
                  <div className="two column row">
                    <div className="four wide column">
                      <div id="projectList"></div>
                    </div>
                    <div className="ten wide column">
                        <div id="projectContent">
                            <span className="ui small grey text">&lt;{t('project.select')}&gt;</span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
    }
};

const ProjectLayout = withTranslation()(ProjectLayoutClass);
export default ProjectLayout;
