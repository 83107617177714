
import React from 'react';
import { useTranslation } from "react-i18next";


const DocLayout = () => {
    const { t } = useTranslation();
    return (
        <div className="ui tab segment" data-tab="docLayout">
            <div className="ui segment">
                <a href="http://docs.projectmeta.hu/Manual/metalang_hu/" target="_blank" rel="noopener noreferrer">{t('doc.manual')}</a>
            </div>
            <h3>{t('doc.package')}:</h3>
            <div className="ui small list">
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystem_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystem</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemDoc_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemDoc</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemEvent_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemEvent</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemExecute_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemExecute</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemFileSystem_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemFileSystem</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemOntology_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemOntology</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemProject_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemProject</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemString_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemString</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemTesting_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemTesting</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemTransform_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemTransform</a>
                    </div>
                </div>
                <div className="item">
                    <i className="file alternate outline icon"></i>
                    <div className="content">
                        <a className="header" href="https://docs.projectmeta.hu/Packages/MetaSystemVersion_library_doc/" target="_blank" rel="noopener noreferrer">MetaSystemVersion</a>
                    </div>
                </div>
            </div>
            <h3>Test packages:</h3>
            <div className="ui small list">
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/packages/hello.zip" target="_blank">Hello</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/packages/hello2.zip" target="_blank">Hello #2</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/packages/target.zip" target="_blank">Target examples</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/packages/generic.zip" target="_blank">Generic project example</a>
                    </div>
                </div>
                <div className="item">
                    <i className="archive icon"></i>
                    <div className="content">
                        <a className="header" href="/packages/package.zip" target="_blank">Package example</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocLayout;
