
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import RepositoryList from './RepositoryList';


class ReposiotryLayoutClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (RootData.repositoryList === null) {
            const container = document.getElementById('repositoryList');
            RootData.repositoryList = createRoot(container);
        }
        var empty = { names: [] };
        RootData.repositoryList.render(
          <React.StrictMode>
            <RepositoryList server={server} items={empty} />
          </React.StrictMode>
        );
    }

    render() {
        return (
            <div className="ui tab segment" data-tab="repositoryLayout">
                <div className="ui grid">
                    <div className="two column row">
                        <div className="five wide column">
                            <div id="repositoryList"></div>
                        </div>
                        <div className="ten wide stretched column">
                            <div id="repositoryContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const ReposiotryLayout = withTranslation()(ReposiotryLayoutClass);
export default ReposiotryLayout;
