

import React from 'react';
import { createRoot } from 'react-dom/client';

import RootData from './RootData';
import ContentSelect from './ContentSelect';
import OntologySelect from './OntologySelect';
import ProjectList from './ProjectList';
import QuerySelect from './QuerySelect';
import RepositoryList from './RepositoryList';
import TargetSelect from './TargetSelect';


const updateFrontendContent = (server, data) => {
    // Project related datas.
    server.updatePackageItems ((data) => {
        if (!RootData.projectList) {
            const container = document.getElementById('projectList');
            RootData.projectList = createRoot(container);
        }
        RootData.projectList.render(
          <React.StrictMode>
            <ProjectList server={server} items={data} />
          </React.StrictMode>
        );
    });
    server.updateProjectList ((data) => {
        if (RootData.targetSelect === null) {
            const container = document.getElementById('targetSelect');
            RootData.targetSelect = createRoot(container);
        }
        RootData.targetSelect.render(
          <React.StrictMode>
            <TargetSelect server={server} items={data} />
          </React.StrictMode>
        );
        if (RootData.contentSelect === null) {
            const container2 = document.getElementById('contentSelect');
            RootData.contentSelect = createRoot(container2);
        }
        RootData.contentSelect.render(
          <React.StrictMode>
            <ContentSelect server={server} items={data} />
          </React.StrictMode>
        );
    });

    // Repository related datas.
    server.updateRepositoryList ((data) => {
        if (RootData.repositoryList === null) {
            const container = document.getElementById('repositoryList');
            RootData.repositoryList = createRoot(container);
        }
        RootData.repositoryList.render(
          <React.StrictMode>
            <RepositoryList server={server} items={data} />
          </React.StrictMode>
        );
        if (RootData.querySelect === null) {
            const container2 = document.getElementById('querySelect');
            RootData.querySelect = createRoot(container2);
        }
        RootData.querySelect.render(
          <React.StrictMode>
            <QuerySelect items={data} />
          </React.StrictMode>
        );
        if (RootData.ontologySelect === null) {
            const container3 = document.getElementById('ontologySelect');
            RootData.ontologySelect = createRoot(container3);
        }
        RootData.ontologySelect.render(
          <React.StrictMode>
            <OntologySelect items={data} />
          </React.StrictMode>
        );
    });
}

export default updateFrontendContent;
