
import React from 'react';
import { withTranslation } from 'react-i18next';


class CloseSelectClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            items: {names: []}
        };
    }

    componentDidMount() {
        this.props.server.updateProjectList ((data) => {
            this.setState({
                isLoaded: true,
                items: data
            });
        });
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return <div> {t('generic.Loading')} </div>;
        }
        var items;
        if (this.props.items)
            items = this.props.items;
        else
            items = this.state.items;
        items.names.sort();
        const elements = items.names.map((name, index) =>
            <option key={index}>{name}</option>
        );
    return (
        <select id="closeSelectData" className="ui selection dropdown">
            {elements}
        </select>
      );
  }
}

const CloseSelect = withTranslation()(CloseSelectClass);
export default CloseSelect;