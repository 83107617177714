
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App';
import updateFrontendContent from './update.js';

//-import Server from './server.js';
import Server from './wa.js';


var server = new Server ();
window.server = server;


server.initializeFrontend (() => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <App server={server} />
        </React.StrictMode>
    );
})

server.updateFrontend (() => {
    updateFrontendContent (server);
})

