
import React from 'react';
import { withTranslation } from 'react-i18next';


class RepositoryContentClass extends React.Component {
    render() {
        const { t } = this.props;
        if (!this.props.data) {
            return <span className="ui small grey text">&lt;{t('repo.select')}&gt;</span>;
        }
        const data = this.props.data;
        data.packages.sort();
        data.namespaces.sort();
        data.workspaces.sort();
        const packages = data.packages.map((name, index) =>
            <li key={index} value="-">{name}</li>
        );
        const namespaces = data.namespaces.map((name, index) =>
            <li key={index} value="-">{name}</li>
        );
        const workspaces = data.workspaces.map((name, index) =>
            <li key={index} value="-">{name}</li>
        );
        return (
            <ol className="ui list">
                <li value="*">{t('repo.Name')}: '{data.name}'</li>
                <li value="*">{t('repo.ContentSize')}: {data.size}</li>
                <li value="*">
                    {t('repo.ImportedPackages')}:
                    <ol>
                        {packages}
                    </ol>
                </li>
                <li value="*">
                    {t('repo.Namespaces')}:
                    <ol>
                        {namespaces}
                    </ol>
                </li>
                <li value="*">
                    {t('repo.Workspaces')}:
                    <ol>
                        {workspaces}
                    </ol>
                </li>
            </ol>
        );
    }
}

const RepositoryContent = withTranslation()(RepositoryContentClass);
export default RepositoryContent;