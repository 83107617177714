
import React from 'react';


class ContentList extends React.Component {
    constructor(props) {
        super(props);
        this.onContentClick = this.onContentClick.bind(this);
    }

    onContentClick (name) {
        const projectName = this.props.projectName;
        const server = this.props.server;
        server.updateProjectContent (projectName, name, (data) => {
            var fileContent = document.getElementById("fileContent");
            fileContent.innerHTML = "<pre>"+data+"</pre>";
        });
    }

    render() {
        const items = this.props.items;
        items.files.sort();
        const elements = items.files.filter((name) => name.endsWith("/") === false).map((name, index) =>
            <div key={index} className="item" onClick={() => this.onContentClick(name)}>
                <i className="file outline icon"></i>
                <div className="content">
                    <div className="header">{name}</div>
                </div>
                </div>
        );
        return (
            <div className="ui small selection list">
                {elements}
            </div>
          );
    }
}

export default ContentList;