
import React from 'react';
import { useTranslation } from "react-i18next";


const LicenseDialog = () => {
    const { t } = useTranslation();
    return (
        <div className="ui small modal" id="license">
            <div className="header"> {t('dialog.License')} </div>
            <div className="content">
                <p>{t('dialog.testing')}</p>
                <p>{t('dialog.store')}</p>
            </div>
            <div className="actions">
                <div className="ui ok button"> {t('dialog.Close')} </div>
            </div>
        </div>
    );
};

export default LicenseDialog;
