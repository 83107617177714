
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import updateFrontendContent from './update.js';


class ProjectContentClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedList: []
        };
        this.onProjectLoadClick = this.onProjectLoadClick.bind(this);
        this.onProjectCloseClick = this.onProjectCloseClick.bind(this);
        this.onProjectRemoteClick = this.onProjectRemoteClick.bind(this);
    }

    onProjectLoadClick(path) {
        const server = this.props.server;
        server.loadProject(path, () => {
            updateFrontendContent (server);
            server.updatePackageDetails (this.props.details.name, (details) => {
                if (!RootData.projectContent) {
                    const container = document.getElementById('projectContent');
                    RootData.projectContent = createRoot(container);
                }
                RootData.projectContent.render(
                    <React.StrictMode>
                        <ProjectContent server={server} details={details} />
                    </React.StrictMode>
                );
            });
        });
    }

    onProjectCloseClick(name) {
        const server = this.props.server;
        server.closeProject(name, () => {
            updateFrontendContent (server);
            server.updatePackageDetails (name, (details) => {
                if (RootData.projectContent === null) {
                    const container = document.getElementById('projectContent');
                    RootData.projectContent = createRoot(container);
                }
                RootData.projectContent.render(
                    <React.StrictMode>
                        <ProjectContent server={server} details={details} />
                    </React.StrictMode>
                );
            });
        });
    }

    onProjectRemoteClick(name) {
        const server = this.props.server;
        server.loadRemoteProject(name, () => {
            updateFrontendContent (server);
            var list = this.state.loadedList;
            list.push(name);
            this.setState({
                loadedList: list
            });
        });
    }

    render() {
        const { t } = this.props;
        const remote = this.props.remote;
        if (remote) {
            for (var i of this.state.loadedList) {
                if (i === remote) {
                    return (
                        <div>
                            <p>{t('project.PackageName')}: {remote}.zip</p>
                            <button className="ui disabled button" type='submit' onClick={() => this.onProjectCloseClick(remote)}>{t('project.CloseRemote')}</button>
                        </div>
                    );
                }
            }
            return (
                <div>
                    <p>{t('project.PackageName')}: {remote}.zip</p>
                    <button className="ui button" type='submit' onClick={() => this.onProjectRemoteClick(remote)}>{t('project.LoadRemote')}</button>
                </div>
            );
        }
        const details = this.props.details;
        if (details.content) {
            // Rendered details
            return (
                <div>
                    <button className="ui button" type='submit' onClick={() => this.onProjectCloseClick(details.name)}>{t('project.Close')}</button>
                    <div dangerouslySetInnerHTML={{ __html:  details.content}}></div>
                </div>
            );
        }
        const provides = details.provides.map((name, index) =>
            <li key={index} value="-">{name}</li>
        );
        const requires = details.requires.map((name, index) =>
            <li key={index} value="-">{name}</li>
        );
        return (
            <div>
                <button className="ui button" type='submit' onClick={() => this.onProjectLoadClick(details.path)}> {t('project.Load')} </button>
                <ul className="ui list">
                    <li>{t('project.Name')}: '{details.name}'</li>
                    <li>{t('project.Description')}: '{details.description}'</li>
                    <li>{t('project.ID')}: {details.id}</li>
                    <li>{t('project.Type')}: {details.type}</li>
                    <li>{t('project.Path')}: '{details.path}'</li>
                    <li>{t('project.License')}: {details.license}</li>
                    <li>{t('project.Version')}: {details.version}</li>
                    <li>{t('project.Timestamp')}: {details.timestamp}</li>
                    <li>{t('project.Provides')}:
                        <ol className="ui list">
                            {provides}
                        </ol>
                    </li>
                    <li>{t('project.Requires')}:
                        <ol className="ui list">
                            {requires}
                        </ol>
                    </li>
                </ul>
            </div>
        );
    }
}

const ProjectContent = withTranslation()(ProjectContentClass);
export default ProjectContent;
