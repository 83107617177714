
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withTranslation } from 'react-i18next';

import RootData from './RootData';
import CloseSelect from './CloseSelect';


class CloseDialogClass extends React.Component {
    componentDidMount() {
        const server = this.props.server;
        if (!RootData.closeSelect) {
            const container = document.getElementById('closeSelect');
            RootData.closeSelect = createRoot(container);
        }
        RootData.closeSelect.render(
            <React.StrictMode>
                <CloseSelect server={server} />
            </React.StrictMode>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ui tiny modal" id="closeDialog">
                <div className="header"> {t('dialog.CloseProject')} </div>
                <div className="content">
                    {t('dialog.CloseProject')}:
                    <div id="closeSelect"></div>
                </div>
                <div className="actions">
                    <div className="ui ok button"> {t('dialog.Ok')} </div>
                    <div className="ui cancel button"> {t('dialog.Cancel')} </div>
                </div>
            </div>
        );
    }
};

const CloseDialog = withTranslation()(CloseDialogClass);
export default CloseDialog;