
var xml_special_to_escaped_one_map = {
    '\n': '<br>',
    ' ': '&nbsp;',
    '\t': '&nbsp;&nbsp;&nbsp;&nbsp;',
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;'
};

var escaped_one_to_xml_special_map = {
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>'
};

export default function encodeXml(string) {
    // eslint-disable-next-line
    return string.replace(/([\&"<>\s])/g, function(str, item) {
        return xml_special_to_escaped_one_map[item];
    });
};

export function decodeXml(string) {
    return string.replace(/(&quot;|&lt;|&gt;|&amp;)/g,
        function(str, item) {
            return escaped_one_to_xml_special_map[item];
    });
}

